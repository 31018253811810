import './App.css';
import MainPage from './Pages/Main';
import "bootswatch/dist/flatly/bootstrap.min.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import TopNavbar from './Components/TopNavbar';
import { isLoggedIn } from './utils';
import LoginPage from './Pages/LoginPage/LoginPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
  return (
    <Router>

      <div>
        <GoogleOAuthProvider clientId={clientId} >
          <TopNavbar />
          <Routes>

            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<MainPage />} />
          </Routes>
        </GoogleOAuthProvider>
      </div>
    </Router>
  );
}

export default App;
