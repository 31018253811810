import { GoogleLogin, CredentialResponse, googleLogout } from '@react-oauth/google';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';

interface DecodedToken extends JwtPayload {
    email: string;
    name: string;
    given_name: string;
    picture: string;
}

export default function GoogleLoginBox() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [pictureUrl, setPictureUrl] = useState('');

    useEffect(() => {
        setIsLoggedIn(localStorage.getItem('user') !== null);
        setPictureUrl(localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')!).picture : '');
    })

    const responseGoogle = (response: CredentialResponse) => {
        if (response.credential) {
            const decoded = jwtDecode<DecodedToken>(response.credential);
            console.log(decoded);
            localStorage.setItem('user', JSON.stringify(decoded));
            setIsLoggedIn(true);
            setPictureUrl(decoded.picture);
            // redirect to homepage
            window.location.href = '/home';
        }
    }
    function logout() {
        localStorage.removeItem('user');
        googleLogout();
        setIsLoggedIn(false);
        window.location.href = '/login';
    }

    return (
        <div>
            {isLoggedIn ?
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary">
                        <Image src={pictureUrl} roundedCircle width={24} height={24} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                :
                <GoogleLogin
                    onSuccess={responseGoogle}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />}
        </div>
    )
}