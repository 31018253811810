import { Row } from "react-bootstrap";
import DownloadBox from "../Components/DownloadBox";


type ContentPageProps = {
    headline: string;
    content: string
    imageURL?: string;
    internalLink?: string;
    internalLinkLabel?: string;
};

function getJsonToDownload(contentPage: ContentPageProps): object {
    const data = {
        headline: contentPage.headline,
        content: contentPage.content
    };
    return data;
}

function getHtmlToDownload(contentPage: ContentPageProps): string {
    return `
        <h2>${contentPage.headline}</h2>
        <div>${contentPage.content.split('\n').map(paragraph => `<p>${paragraph}</p>`).join('')}</div>
        ${contentPage.imageURL ? `<img src="${contentPage.imageURL}" alt="${contentPage.headline}" />` : ''}
        ${contentPage.internalLink ? `<a href="${contentPage.internalLink}">${contentPage.internalLinkLabel}</a>` : ''}
    `;
}



export default function BuyingGuideArticlePage(props: ContentPageProps) {
    return (
        <div>
            <h2>{props?.headline}</h2>
            <div dangerouslySetInnerHTML={{ __html: props?.content?.split('\n').map(paragraph => `<p>${paragraph}</p>`).join('') }} />
            {props.imageURL ?
                <Row className="justify-content-md-center">
                    <img src={props?.imageURL} alt={props?.headline} style={{ width: "500px" }} />
                </Row> : null}
            {props.internalLink ? <a href={props.internalLink}>{props?.internalLinkLabel}</a> : null}
            <DownloadBox getJson={() => getJsonToDownload(props)} getHtml={() => getHtmlToDownload(props)} />
        </div>
    );
}
