import GoogleLoginBox from "../../Components/GoogleLoginBox";

export default function LoginPage() {
    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    <h1>Welcome to Novelteer!</h1>
                    <p className="lead">Unlock the full potential of your marketing content with Novelteer's cutting-edge SEO optimization tools.</p>
                    <p>
                        <strong>Drive real results</strong> with our platform, designed to help you achieve:
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card border-light">
                                <div className="card-body">
                                    <i className="fas fa-chart-line fa-2x mb-2"></i>
                                    <h5 className="card-title">Boost Organic Search Rankings</h5>
                                    <p className="card-text">Improve your online visibility and reach a wider audience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card border-light">
                                <div className="card-body">
                                    <i className="fas fa-dollar-sign fa-2x mb-2"></i>
                                    <h5 className="card-title">Increase Conversions and Revenue</h5>
                                    <p className="card-text">Turn your website traffic into paying customers and boost your bottom line.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="card border-light">
                                <div className="card-body">
                                    <i className="fas fa-pencil-alt fa-2x mb-2"></i>
                                    <h5 className="card-title">Refine Your Content Strategy</h5>
                                    <p className="card-text">Develop a content strategy that resonates with your audience and sets you apart from the competition.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card border-light">
                                <div className="card-body">
                                    <i className="fas fa-clock fa-2x mb-2"></i>
                                    <h5 className="card-title">Streamline Your Workflow</h5>
                                    <p className="card-text">Save time and increase productivity with our intuitive and user-friendly platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Create more compelling content that resonates with your audience and sets you apart from the competition.</p>
                    <div className="d-flex justify-content-center">
                        <GoogleLoginBox />
                    </div>
                </div>
            </div>
        </div>
    );
}