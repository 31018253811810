import { Col, Container, Nav, Row } from "react-bootstrap"
import HomePage from "./HomePage";
import Sidebar from "../Components/Sidebar";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MyFolder from "./MyFolder";
import SingleContentPage from "./SingleContentPage";
import BulkContentPage from "./BulkContentPage";
import { isLoggedIn } from "../utils";

export default function MainPage() {
    if (!isLoggedIn()) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div>
            <Container fluid className="flex-grow-1">
                <Row>
                    <Col xs={2} className="p-0 sticky-top">
                        <Sidebar /> {/* Sidebar on the left */}
                    </Col>
                    <Col xs={9}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/content/single" element={<SingleContentPage />} />
                            <Route path="/content/bulk" element={<BulkContentPage />} />
                            <Route path="/folder" element={<MyFolder />} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}