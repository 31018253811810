import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsFiletypeHtml, BsFiletypeJson } from "react-icons/bs";
import "./style.css";


function downloadJson(getJson: () => object) {
    const data = getJson();
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "content.json";
    a.click();
}

function downloadHtml(getHtml: () => string) {
    const html = getHtml();
    const blob = new Blob([html], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "content.html";
    a.click();
}

type DownloadBoxProps = {
    getJson: () => object;
    getHtml: () => string;
};

function getIcon(format: string): JSX.Element {
    return format === "json" ? (
        <BsFiletypeJson />
    ) : (
        <BsFiletypeHtml />
    );
}


/**
 * A component that displays two buttons to download content in two formats: JSON and HTML.
 *
 * @param {DownloadBoxProps} props
 * @prop {() => object} getJson A function that returns the JSON content to be downloaded.
 * @prop {() => string} getHtml A function that returns the HTML content to be downloaded.
 * @returns {JSX.Element} A div containing two buttons to download the content.
 */
export default function DownloadBox(props: DownloadBoxProps) {
    return (
        <div className="download">
            {["json", "html"].map(format => (
                <OverlayTrigger
                    key={format}
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip>
                            Download as {format.toUpperCase()}
                        </Tooltip>
                    }
                >
                    <Button variant="outline-secondary" onClick={() => (format === "json" ? downloadJson(props.getJson) : downloadHtml(props.getHtml))}>
                        {getIcon(format)}
                    </Button>

                </OverlayTrigger>
            ))}
        </div>
    );
}