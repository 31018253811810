import { Container, Nav, Navbar } from "react-bootstrap";
import GoogleLoginBox from "./GoogleLoginBox";
import { isLoggedIn } from "../utils";

export default function TopNavbar() {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/home">Novelteer</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link> */}
                    </Nav>
                    <Nav className="ml-auto" hidden={!isLoggedIn()}>
                        <GoogleLoginBox />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}