import { Collapse, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { useState } from 'react';
import { CiLock } from "react-icons/ci";


export default function Sidebar() {
    const [open, setOpen] = useState(true);

    function renderTooltip(props: any) {
        return (
            <Tooltip {...props}>
                For PRO users only
            </Tooltip>
        )
    }

    function LockedItem({ label }: { label: string }) {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <span>
                    {label} <CiLock />
                </span>
            </OverlayTrigger>
        )
    }

    return (
        <Nav className="flex-column sidebar vh-100 ">
            <Nav.Item>
                <LinkContainer to="/home">
                    <Nav.Link>Home</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className="text-muted" style={{ pointerEvents: 'none' }} >
                    {/* <Nav.Link onClick={() => setOpen(!open)} className="dropdown-toggle"> */}
                    Generate Content
                </Nav.Link>
                <Collapse in={open}>
                    <div>
                        <LinkContainer to="/content/single">
                            <Nav.Link className="sub-item">Single</Nav.Link>
                        </LinkContainer>
                        <LinkContainer
                            to="/content/bulk"
                            className="text-muted blocked"
                            onClick={(e) => e.preventDefault()}
                        >
                            <Nav.Link className="sub-item"><LockedItem label="Bulk" /></Nav.Link>
                        </LinkContainer>
                    </div>
                </Collapse>
            </Nav.Item>
            <Nav.Item>
                <LinkContainer to="/folder" className="blocked text-muted">
                    <Nav.Link><LockedItem label="My Folder" /></Nav.Link>
                </LinkContainer>
            </Nav.Item>
        </Nav>
    )
}