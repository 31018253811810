import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import BuyingGuidePage from "../ContentPages/BuyingGuidePage";
import { ProductReviewPage } from "../ContentPages";
import { BuyingGuideArticleProps, BuyingGuidePageProps, getBuyingGuideArticleFromServer, getBuyingGuideFromServer, getProductReviewFromServer, ProductReviewProps } from "../api";
import BuyingGuideArticlePage from "../ContentPages/BuyingGuideArticlePage";

const inputPropsByContentType = {
    buying_guide: {
        option: "Buying Guide",
        label: "Category",
        placeholder: "Earbuds",
    },
    buying_guide_article: {
        option: "Buying Guide Article",
        label: "Category",
        placeholder: "Earbuds",
    },
    product_review: {
        option: "Product Review",
        label: "Product and model",
        placeholder: "Apple AirPods 4",
    },
};

const intonations = {
    "Formal and Objective": "formal",
    "Informal and Engaging": "informal",
    "Persuasive and Opinionated": "persuasive"
}

export default function SingleContentPage() {
    const [BuyingGuideContentPage, setBuyingGuideContentPage] = useState<BuyingGuidePageProps | null>(null);
    const [BuyingGuideArticleContentPage, setBuyingGuideArticleContentPage] = useState<BuyingGuideArticleProps | null>(null);
    const [productReview, setProductReview] = useState<ProductReviewProps | null>(null);
    const [category, setCategory] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [contentType, setContentType] = useState<string>("buying_guide");
    const [imageURL, setImageURL] = useState<string>("");
    const [internalLink, setInternalLink] = useState<string>("");
    const [keywords, setKeywords] = useState<string | undefined>(undefined);
    const [intonation, setIntonation] = useState<string>("formal");

    async function generateContent(e: React.FormEvent) {
        e.preventDefault();
        setProductReview(null);
        setBuyingGuideContentPage(null);
        setLoading(true);
        if (contentType === "buying_guide") {
            const buyginGuide = await getBuyingGuideFromServer({ category, keywords, tone: intonation });
            setBuyingGuideContentPage(buyginGuide);
        } else if (contentType === "buying_guide_article") {
            const buyginGuide = await getBuyingGuideArticleFromServer({ category, keywords, tone: intonation });
            setBuyingGuideArticleContentPage(buyginGuide);
        } else {
            const productReview = await getProductReviewFromServer(category);
            setProductReview(productReview);
        }
        setLoading(false);
    }
    return (
        <div>
            <h1>Generate Single Content Page</h1>
            <Form onSubmit={generateContent}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}><strong>Type</strong></Form.Label>
                    <Col sm={4}>
                        <Form.Select value={contentType} onChange={(e) => {
                            setContentType(e.target.value);
                            setCategory("");
                        }}>
                            {Object.keys(inputPropsByContentType).map((key) => (
                                <option key={key} value={key}>{inputPropsByContentType[key as keyof typeof inputPropsByContentType].option}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}><strong>Tone:</strong></Form.Label>
                    <Col sm={4}>
                        <Form.Select value={intonation} onChange={(e) => setIntonation(e.target.value)}>
                            {Object.keys(intonations).map((key) => (
                                <option key={key} value={intonations[key as keyof typeof intonations]}>{key}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}><strong>{inputPropsByContentType[contentType as keyof typeof inputPropsByContentType].label}</strong></Form.Label>
                    <Col sm={10}>
                        <Form.Control required type="text" placeholder={inputPropsByContentType[contentType as keyof typeof inputPropsByContentType].placeholder} value={category} onChange={(e) => setCategory(e.target.value)} style={{ width: '400px' }} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}><strong>Image URL</strong></Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="https://example.com/image.jpg" value={imageURL} onChange={(e) => setImageURL(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}><strong>Internal Link</strong></Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="/category/earbuds" value={internalLink} onChange={(e) => setInternalLink(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}><strong>SEO Keywords</strong></Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="earbuds, headphones, wireless" value={keywords} onChange={(e) => setKeywords(e.target.value)} />
                        <Form.Text className="text-muted">
                            Enter keywords separated by commas.
                        </Form.Text>
                    </Col>
                </Form.Group>
                {loading ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    <Button variant="primary" type="submit">
                        Generate
                    </Button>
                }
            </Form>

            <hr style={{ margin: '20px 0' }} />


            {BuyingGuideContentPage ? <BuyingGuidePage {...BuyingGuideContentPage} imageURL={imageURL} internalLink={internalLink} internalLinkLabel={category} /> : null}
            {BuyingGuideArticleContentPage ? <BuyingGuideArticlePage {...BuyingGuideArticleContentPage} imageURL={imageURL} internalLink={internalLink} internalLinkLabel={category} /> : null}
            {productReview ? <ProductReviewPage review={productReview} /> : null}

        </div>
    );
}
