import { Row } from "react-bootstrap";
import DownloadBox from "../Components/DownloadBox";


type ContentPageProps = {
    headline: string;
    subheadline: string;
    overview: string;
    benefits: string[];
    buyingGuideTips: string[];
    faq: { q: string; a: string }[];
    conclusion: string;
    imageURL?: string;
    internalLink?: string;
    internalLinkLabel?: string;
};

function getJsonToDownload(contentPage: ContentPageProps): object {
    const data = {
        headline: contentPage.headline,
        subheadline: contentPage.subheadline,
        overview: contentPage.overview,
        benefits: contentPage.benefits,
        buyingGuideTips: contentPage.buyingGuideTips,
        faq: contentPage.faq,
        conclusion: contentPage.conclusion,
    };
    return data;
}

function getHtmlToDownload(contentPage: ContentPageProps): string {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${contentPage.headline}</title>
    </head>
    <body>
        <h2>${contentPage.headline}</h2>
        <h3>${contentPage.subheadline}</h3>
        <p>${contentPage.overview}</p>
        <h4>Benefits</h4>
        <ul>
        ${contentPage.benefits.map(benefit => `<li>${benefit}</li>`).join('')}
        </ul>
        <h4>Buying Guide Tips</h4>
        <ul>
        ${contentPage.buyingGuideTips.map(tip => `<li>${tip}</li>`).join('')}
        </ul>
        ${contentPage.imageURL ? `<img src="${contentPage.imageURL}" alt="${contentPage.headline}" style="width: 500px;">` : ''}
        <h4>FAQ</h4>
        <ul>
        ${contentPage.faq.map(faq => `<li><h5>Q: ${faq.q}</h5><p>A: ${faq.a}</p></li>`).join('')}
        </ul>
        ${contentPage.internalLink ? `<a href="${contentPage.internalLink}">${contentPage.internalLinkLabel}</a>` : ''}
    </body>
    </html>
    `;
}



export default function BuyingGuidePage(props: ContentPageProps) {
    return (
        <div>
            <h2>{props?.headline}</h2>
            <h3>{props?.subheadline}</h3>
            <p>{props?.overview}</p>
            <h4>Benefits</h4>
            <ul>
                {props?.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                ))}
            </ul>
            <h4>Buying Guide Tips</h4>
            <ul>
                {props?.buyingGuideTips.map((tip, index) => (
                    <li key={index}>{tip}</li>
                ))}
            </ul>
            {props.imageURL ?
                <Row className="justify-content-md-center">
                    <img src={props?.imageURL} alt={props?.headline} style={{ width: "500px" }} />
                </Row> : null}
            <h4>FAQ</h4>
            <ul>
                {props?.faq.map((faq, index) => (
                    <li key={index}>
                        <h5>Q: {faq.q}</h5>
                        <p>A: {faq.a}</p>
                    </li>
                ))}
            </ul>
            {props.internalLink ? <a href={props.internalLink}>{props?.internalLinkLabel}</a> : null}
            <DownloadBox getJson={() => getJsonToDownload(props)} getHtml={() => getHtmlToDownload(props)} />
        </div>
    );
}
