import { ProductReviewProps } from "../api";
import DownloadBox from "../Components/DownloadBox";

type ProductReviewPageProps = {
    review: ProductReviewProps
}

function getHtmlToDownload(props: ProductReviewPageProps): string {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${props.review.title}</title>
    </head>
    <body>
        <h1>${props.review.title}</h1>
        <p>${props.review.peronal_experience}</p>
        <h3>Features</h3>
        <ul>
        ${props.review.features.map(feature => `<li>${feature}</li>`).join('')}
        </ul>
        <h3>Pros</h3>
        <ul>
        ${props.review.pros.map(pro => `<li>${pro}</li>`).join('')}
        </ul>
        <h3>Cons</h3>
        <ul>
        ${props.review.cons.map(con => `<li>${con}</li>`).join('')}
        </ul>
        <h3>Verdict</h3>
        <h6>${props.review.verdict}</h6>
    </body>
    </html>
    `;
}

export default function ProductReviewPage(props: ProductReviewPageProps) {
    return (
        <div>
            <h1>{props.review.title}</h1>
            <p>{props.review.peronal_experience}</p>
            <h3>Features</h3>
            <ul>
                {props.review.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
            <h3>Pros</h3>
            <ul>
                {props.review.pros.map((pro, index) => (
                    <li key={index}>{pro}</li>
                ))}
            </ul>
            <h3>Cons</h3>
            <ul>
                {props.review.cons.map((con, index) => (
                    <li key={index}>{con}</li>
                ))}
            </ul>
            <h3>Verdict</h3>
            <h6>{props.review.verdict}</h6>
            <DownloadBox getJson={() => props.review} getHtml={() => getHtmlToDownload(props)} />
        </div>
    );
}