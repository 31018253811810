const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "";

export type BuyingGuidePageProps = {
    headline: string;
    subheadline: string;
    overview: string;
    benefits: string[];
    buyingGuideTips: string[];
    faq: { q: string; a: string }[];
    conclusion: string;
};

export type BuyingGuideArticleProps = {
    headline: string;
    content: string;
}

export type ProductReviewProps = {
    title: string;
    peronal_experience: string;
    features: string[];
    pros: string[];
    cons: string[];
    verdict: string;
}

type buyingGuideApiParams = {
    category: string;
    keywords?: string;
    tone: string;
}

export async function getBuyingGuideFromServer({ category, keywords, tone }: buyingGuideApiParams): Promise<BuyingGuidePageProps> {
    const URL = `${BACKEND_URL}/content?input=${category}&contentType=buying_guide&tone=${tone}${keywords ? `&keywords=${keywords}` : ""}`;
    const request = await fetch(URL);
    const contentPage = await request.json() as BuyingGuidePageProps;
    return contentPage;
}

export async function getBuyingGuideArticleFromServer({ category, keywords, tone }: buyingGuideApiParams): Promise<BuyingGuideArticleProps> {
    const URL = `${BACKEND_URL}/content?input=${category}&contentType=buying_guide_article&tone=${tone}${keywords ? `&keywords=${keywords}` : ""}`;
    const request = await fetch(URL);
    const contentPage = await request.json() as BuyingGuideArticleProps;
    return contentPage;
}

export async function getProductReviewFromServer(input: string): Promise<ProductReviewProps> {
    const URL = `${BACKEND_URL}/content?input=${input}&contentType=product_review`;
    const request = await fetch(URL);
    const content = await request.json() as ProductReviewProps;
    return content;
}